var tableColumns = [{
  title: "Round",
  dataIndex: "cycle",
  key: "cycle",
  scopedSlots: {
    customRender: "cycle"
  }
}, {
  title: "开始时间",
  dataIndex: "cyclestarttime",
  key: "cyclestarttime",
  scopedSlots: {
    customRender: "cyclestarttime"
  }
}, {
  title: "结算时间",
  dataIndex: "settletime",
  key: "settletime",
  scopedSlots: {
    customRender: "settletime"
  }
}, {
  title: "结束时间",
  dataIndex: "cycleendtime",
  key: "cycleendtime",
  scopedSlots: {
    customRender: "cycleendtime"
  }
}, {
  title: "当前状态",
  dataIndex: "status",
  key: "status",
  scopedSlots: {
    customRender: "status"
  }
}, {
  title: "Web2 Pump",
  dataIndex: "web2pump",
  key: "web2pump",
  scopedSlots: {
    customRender: "web2pump"
  }
}, {
  title: "Free Pump",
  dataIndex: "freepump",
  key: "freepump",
  scopedSlots: {
    customRender: "freepump"
  }
}, {
  title: "总Pump资金",
  dataIndex: "pumpamount",
  key: "pumpamount",
  scopedSlots: {
    customRender: "pumpamount"
  }
}, {
  title: "操作",
  key: "action",
  dataIndex: "action",
  fixed: "right",
  scopedSlots: {
    customRender: "action"
  }
}];
export { tableColumns };